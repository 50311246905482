import WaveSurfer from 'wavesurfer.js';

// Hide IE progress bar
const preventIEBar = document.getElementById('preventable-progress-container');
const urgentIEBar = document.getElementById('urgent-progress-container');
const relatedIEBar = document.getElementById('related-progress-container');
preventIEBar.style.display = 'none';
urgentIEBar.style.display = 'none';
relatedIEBar.style.display = 'none';

// Wavesurfer elements
const urgentWavesurfer = WaveSurfer.create({
  container: '#urgent-waveform',
  waveColor: 'white',
  progressColor: '#f2c047',
  cursorColor: '#f2c047',
  cursorWidth: 2,
  barHeight: '4',
  height: 50,
  barWidth: '2',
  scrollParent: false,
  responsive: true,
  barRadius: 2,
});
urgentWavesurfer.load('./assets/audio/urgent.mp3');
const relatedWavesurfer = WaveSurfer.create({
  container: '#related-waveform',
  waveColor: 'white',
  progressColor: '#823180',
  cursorColor: '#823180',
  cursorWidth: 2,
  barHeight: '4',
  height: 50,
  barWidth: '2',
  scrollParent: false,
  responsive: true,
  barRadius: 2,
});
relatedWavesurfer.load('./assets/audio/related.mp3');
const preventableWavesurfer = WaveSurfer.create({
  container: '#preventable-waveform',
  waveColor: 'white',
  progressColor: '#005eaa',
  cursorColor: '#005eaa',
  cursorWidth: 2,
  barHeight: '4',
  height: 50,
  barWidth: '2',
  scrollParent: false,
  responsive: true,
  barRadius: 2,
});
preventableWavesurfer.load('./assets/audio/preventable.mp3');

/**
 * Converts seconds to MM:SS format for display
 * @param {number} seconds number of seconds to convert
 * @returns string with converted time
 */
const convertToDisplayTime = (seconds) => new Date(seconds * 1000).toISOString().substr(15, 4);

/**
 * Handles the audio finishing playing
 * @param {string} id identifier for the audio player
 */
const endAudio = (id) => {
  const player = document.getElementById(`${id}-ap-container`);
  const playButton = document.getElementById(`${id}-play`);
  player.classList.remove('play');
  playButton.innerHTML = '<i class="fas fa-play"></i>';
};

/**
 * Gets the duration for each audio file when the page loads
 */
const getDuration = (waveSurferElement, id) => {
  const duration = waveSurferElement.getDuration();
  const timeDisplay = document.getElementById(`${id}-time`);
  timeDisplay.innerHTML = `0:00/${convertToDisplayTime(duration)}`;
};

/**
 * Opens and closes the volume slider
 * {string} id identifier for the audio player
 */
const openSlider = (id) => {
  const volumeSliderContainer = document.getElementById(`${id}-volume-slider`);
  const volumeSlider = document.getElementById(`${id}-volume-input`);
  const volumeButton = document.getElementById(`${id}-volume`);

  if (window.innerWidth >= 576) {
    if (volumeSliderContainer.classList.contains('d-sm-none')) {
      volumeSliderContainer.classList.toggle('d-sm-none');
      document.body.offsetHeight;
      volumeButton.classList.add('expanded');
      volumeSlider.classList.add('expanded');
    } else {
      document.body.offsetHeight;
      volumeSlider.classList.remove('expanded');
      setTimeout(() => {
        volumeButton.classList.remove('expanded');
        volumeSliderContainer.classList.toggle('d-sm-none');
      }, 100);
    }
  }

  // On mobile the slider is always visible, so that button behavior changes to mute/ unmute
  if (window.innerWidth < 576) {
    if (volumeSlider.value > 0) {
      volumeSlider.value = 0;
      setVolume(id);
    } else {
      volumeSlider.value = 0.5;
      setVolume(id);
    }
  }
};

/**
 * Pauses audio that is currently playing
 * @param {string} id identifier for the audio player
 */
const pauseAudio = (id) => {
  const player = document.getElementById(`${id}-ap-container`);

  player.classList.remove('play');

  switch (id) {
    case 'urgent': {
      urgentWavesurfer.pause();
      break;
    }
    case 'related': {
      relatedWavesurfer.pause();
      break;
    }
    case 'preventable': {
      preventableWavesurfer.pause();
      break;
    }
    default: {
      break;
    }
  }
};

/**
 * Starts and stops the audio when the play button is clicked.
 * @param {string} id identifier for the audio player
 */
const playAudio = (id) => {
  // const audio = document.getElementById(`${id}-audio`);
  const volumeInput = document.getElementById(`${id}-volume-input`);
  const player = document.getElementById(`${id}-ap-container`);
  const playButton = document.getElementById(`${id}-play`);

  // pause the audio if it's currently playing
  if (player.classList.contains('play')) {
    player.classList.remove('play');
    playButton.innerHTML = '<i class="fas fa-play"></i>';
    pauseAudio(id);
    return;
  }

  playButton.innerHTML = '<i class="fas fa-pause"></i>';

  switch (id) {
    case 'urgent': {
      urgentWavesurfer.setVolume(volumeInput.value);
      urgentWavesurfer.play();
      break;
    }
    case 'related': {
      relatedWavesurfer.setVolume(volumeInput.value);
      relatedWavesurfer.play();
      break;
    }
    case 'preventable': {
      preventableWavesurfer.setVolume(volumeInput.value);
      preventableWavesurfer.play();
      break;
    }
    default: {
      break;
    }
  }

  player.classList.add('play');
};

/**
 * Handles input change on the volume slider and sets volume
 * @param {*} id identifier for the audio player
 */
const setVolume = (id) => {
  const volumeInput = document.getElementById(`${id}-volume-input`);

  switch (id) {
    case 'urgent': {
      urgentWavesurfer.setVolume(volumeInput.value);
      break;
    }
    case 'related': {
      relatedWavesurfer.setVolume(volumeInput.value);
      break;
    }
    case 'preventable': {
      preventableWavesurfer.setVolume(volumeInput.value);
      break;
    }
    default: {
      break;
    }
  }

  const audioVolumeButton = document.getElementById(`${id}-volume`);

  if (volumeInput.value < 0.01) {
    audioVolumeButton.firstElementChild.classList.remove('fa-volume-up');
    audioVolumeButton.firstElementChild.classList.add('fa-volume-slash');
  } else {
    audioVolumeButton.firstElementChild.classList.remove('fa-volume-slash');
    audioVolumeButton.firstElementChild.classList.add('fa-volume-up');
  }
};

/**
 * Updates the scrubber bar and time display as audio plays
 * @param {Event} e audio updated event
 * @param {*} id identifier for the audio player
 */
const updateProgress = (id) => {
  const timeDisplay = document.getElementById(`${id}-time`);

  const updateTime = (waveSurferElement) => {
    const elapsed = waveSurferElement.getCurrentTime();
    const duration = waveSurferElement.getDuration();
    const elapsedDisplay = convertToDisplayTime(elapsed);
    const durationDisplay = convertToDisplayTime(duration);
    timeDisplay.innerHTML = `${elapsedDisplay}/${durationDisplay}`;
  };

  switch (id) {
    case 'urgent': {
      updateTime(urgentWavesurfer);
      break;
    }
    case 'related': {
      updateTime(relatedWavesurfer);
      break;
    }
    case 'preventable': {
      updateTime(preventableWavesurfer);
      break;
    }
    default: break;
  }
};

// urgent event listeners
document.getElementById('urgent-play').addEventListener('click', () => {
  playAudio('urgent');
});

document.getElementById('urgent-volume-input').addEventListener('input', () => {
  setVolume('urgent');
});

document.getElementById('urgent-volume').addEventListener('click', () => {
  openSlider('urgent');
});

urgentWavesurfer.on('audioprocess', () => {
  updateProgress('urgent');
});

urgentWavesurfer.on('finish', () => {
  endAudio('urgent');
});
urgentWavesurfer.on('ready', () => {
  getDuration(urgentWavesurfer, 'urgent');
});

// related event listeners
document.getElementById('related-play').addEventListener('click', () => {
  playAudio('related');
});

document
  .getElementById('related-volume-input')
  .addEventListener('input', () => {
    setVolume('related');
  });

document.getElementById('related-volume').addEventListener('click', () => {
  openSlider('related');
});

relatedWavesurfer.on('audioprocess', () => {
  updateProgress('related');
});

relatedWavesurfer.on('finish', () => {
  endAudio('related');
});
relatedWavesurfer.on('ready', () => {
  getDuration(relatedWavesurfer, 'related');
});

// preventable event listeners
document.getElementById('preventable-play').addEventListener('click', () => {
  playAudio('preventable');
});

document
  .getElementById('preventable-volume-input')
  .addEventListener('input', () => {
    setVolume('preventable');
  });

document.getElementById('preventable-volume').addEventListener('click', () => {
  openSlider('preventable');
});

preventableWavesurfer.on('audioprocess', () => {
  updateProgress('preventable');
});

preventableWavesurfer.on('finish', () => {
  endAudio('preventable');
});
preventableWavesurfer.on('ready', () => {
  getDuration(preventableWavesurfer, 'preventable');
});
